<template>
    <div>
    <div style="background-color: #000000; text-align: center"><router-link to="/" class="font-extrabold" style="color: #ffffff !important;"><span style="color: #970000">L</span>egion<span style="color: #970000">C</span>raft<span style="color: #970000">S</span>tudios</router-link></div>
    <div class="home flex justify-center" >

        <v-img class="fade-in-image" alt="mintality logo" src="@/assets/mintality_logo_4.png" contain max-height="500"></v-img>

    </div>

    <v-container class="text-center">
        <v-row class="text-6xl" style="color:#0ed9b0">
            <v-col cols="12" >Solutions Architects</v-col>
        </v-row>
        <v-row class="text-4xl">
            <v-col cols="12" >Startup & Growth</v-col>
        </v-row>
    </v-container>

    <h2 class="text-6xl text-center m-4 lg:m-16" style="color:#0ed9b0; font-family: continuo, sans-serif; ">We are your partner for the following services</h2>
    <div class="p-4 text-center wrap flex flex-wrap justify-center">

        <div class="w-full flex justify-center">
            <div class="card" style="max-width: 1000px;">
                <h2 class="text-6xl text-center font-bold" style="color:#0ed9b0">BP & WF</h2>
                <p style="font-size: 18px">Business Processes & Workflows</p>
                <v-row class="m-4">
                  <v-col cols="12">
                    <div class="flex flex-wrap justify-center p-4 text-center">
                      <img style="border-radius: 20px; width: 180px; height: 180px" alt="superSati logo" src="@/assets/servicesLogos/BPFW.png" />
                    </div>
                  </v-col>
                </v-row>
                <p>Analysis - Design - Optimization</p>
            </div>
        </div>

        <div class="w-full flex justify-center">
            <div class="card" style="max-width: 1000px;">
                <h2 class="text-4xl text-center font-bold" style="color:#0ed9b0">Development</h2>
                <v-row class="m-4">
                  <v-col cols="12" lg="3">
                    <div class="flex flex-wrap justify-center p-4 text-center">
                        <a target="blank" class="flex justify-center" href="https://laravel.com/"><img style="border-radius: 20px; width: 180px; height: 180px" alt="superSati logo" src="@/assets/servicesLogos/laravel.png" /></a>
                        <p class="w-full font-bold text-2xl">Laravel</p>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <div class="flex flex-wrap justify-center p-4 text-center">
                        <a target="blank" class="flex justify-center" href="https://vuejs.org/"><img style="border-radius: 20px; width: 180px; height: 180px" alt="superSati logo" src="@/assets/servicesLogos/vue.png" /></a>
                        <p class="w-full font-bold text-2xl">Vue</p>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <div class="flex flex-wrap justify-center p-4 text-center">
                        <a target="blank" class="flex justify-center" href="https://ionicframework.com/"><img style="border-radius: 20px; width: 180px; height: 180px" alt="smrk logo" src="@/assets/servicesLogos/ionic.png" /></a>
                        <p class="w-full font-bold text-2xl">Ionic</p>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <div class="flex flex-wrap justify-center p-4 text-center">
                        <a target="blank" class="flex justify-center" href="https://wordpress.org/"><img style="border-radius: 20px; width: 180px; height: 180px" alt="smrk logo" src="@/assets/servicesLogos/wordpress.png" /></a>
                        <p class="w-full font-bold text-2xl">Wordpress</p>
                    </div>
                  </v-col>
                </v-row>
            </div>
        </div>

        <div class="w-full flex justify-center">
            <div class="card" style="max-width: 1000px;">
                <h2 class="text-4xl text-center font-bold" style="color:#0ed9b0">Implementation <br> &<br> Administration</h2>
                <v-row class="m-4">
                  <v-col cols="12" lg="4">
                    <div class="flex flex-wrap justify-center p-4 text-center">
                        <a target="blank" class="flex justify-center" href="https://www.microsoft.com/en/microsoft-365"><img style="border-radius: 20px; width: 180px; height: 180px" alt="superSati logo" src="@/assets/servicesLogos/microsoft365.png" /></a>
                        <p class="w-full font-bold text-2xl">Microsoft 365</p>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <div class="flex flex-wrap justify-center p-4 text-center">
                        <a target="blank" class="flex justify-center" href="https://workspace.google.com/intl/en/"><img style="border-radius: 20px; width: 180px; height: 180px" alt="smrk logo" src="@/assets/servicesLogos/googleWorkspace.png" /></a>
                        <p class="w-full font-bold text-2xl">Google Workspace</p>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <div class="flex flex-wrap justify-center p-4 text-center">
                        <a target="blank" class="flex justify-center" href="https://www.notion.so/"><img style="border-radius: 20px; width: 180px; height: 180px" alt="smrk logo" src="@/assets/servicesLogos/notion.png" /></a>
                        <p class="w-full font-bold text-2xl">Notion</p>
                    </div>
                  </v-col>
                </v-row>
            </div>
        </div>

    </div>

  <!-- <div class="mt-8 flex justify-center pt-16">
    <h1 class="text-6xl text-red-500 text-center" style="color:#970000; font-family: continuo, sans-serif">Our Work</h1>
  </div>

  <v-row class="m-4 md:m-16 lg:mx-32">
    <v-col cols="12" lg="6">
      <div class="flex flex-wrap justify-center p-4 text-center">
          <a target="blank" class="flex justify-center" href="https://yalla2go.legioncraftstudios.com/info"><img style="border-radius: 20px; width: 180px; height: 180px" alt="superSati logo" src="@/assets/servicesLogos/y2g.png" /></a>
          <div class="w-full project-card-title-crafted">Yalla2Go</div>
          <div class="w-full project-card-text">Restaurant <br>Reservation App</div>
      </div>
    </v-col>
    <v-col cols="12" lg="6">
      <div class="flex flex-wrap justify-center p-4 text-center">
          <img style="border-radius: 20px; width: 180px; height: 180px" alt="smrk logo" src="@/assets/servicesLogos/moshpic.png" />
          <div class="w-full project-card-title-crafted">MoShPic</div>
          <div class="w-full project-card-text">Mohamed Shawky <br>Art Portfolio</div>
      </div>
    </v-col>
  </v-row> -->

  <contact-us title="<h1 style='color: #0ed9b0'>Contact Us</h1>" text="For hiring us please send us a contact request with your information and we will get back to you ASAP"/>



</div>
</template>

<script>
import ContactUs from '@/views/ContactUs.vue'

export default {
  name: 'Home',
  components: {
    ContactUs
  }
}
</script>

<style>
.fade-in-image {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
</style>