<template>
<div>
  <div style="background-color: #0ed9b0; text-align: center"><router-link to="/mintality" class="font-extrabold" style="color: #ffffff !important;"><span style="color:black">mint</span>ality</router-link></div>
  <div class="home p-0 flex justify-center fade-in-image">

    <img class="w-full" alt="legion craft studios logo" src="@/assets/servicesLogos/lcs_home_3.png" contain max-height="500">

  </div>

  <div class="text-center">
    <h2 class="text-6xl mt-8" style="color:#970000; font-family: continuo, sans-serif">Experience Crafting</h2>
  </div>

  <div class="p-4 text-center wrap flex flex-wrap justify-center">

    <div class="card" style="max-width: 1000px;">
      <h2 class="text-center" style="color:#970000; font-family: continuo, sans-serif; font-size: 32px">We do not develop projects<br>we craft experiences</h2>
      
      <p>
        Quirky ideas coming to life! <br>
        We may tread on familiar waters but we never play it safe
      </p>
    </div>
        
    <div class="card" style="max-width: 1000px;">
      <h2 class="text-center" style="color:#970000; font-family: continuo, sans-serif; font-size: 32px">Our Core Beliefs & Values</h2>
      <p>
        We believe in making the world a better place by crafting experiences that enhances our quality of life, communication & entertainment. 
      </p>
      <p class="text-bold text-xl">~</p>
      <p>
        We believe technology should enhance our quality of life and help us reach higher selves and not monetize us, our time and our attention.
      </p>
      <p class="text-bold text-xl">~</p>
      <p>
        We believe that our work and our lives are not two separate entities, it is part of our souls and our souls are poured into it.
      </p>
      <p class="text-bold text-xl">~</p>
      <p>
        We believe in open collaboration, accidental interactions, random creativity. We oscilate between up in the air "l'art pour l'art" & strict overzealous efficiency.
      </p>
    </div>

    <div style="width: 1000px;" class="flex flex-wrap justify-center">
      <h2 class="text-6xl text-center w-full mt-8 project-card-title-crafted">Amr El Fouly</h2>
      <img class="box" style="border-radius: 200px; width: 180px; height: 180px" alt="amr el fouly image" src="@/assets/amrelfouly.jpg" />
      <h3 class="text-6xl text-center w-full project-card-title-crafted">Founder</h3>
    </div>
  </div>

  <div class="mt-8 flex justify-center py-4 pt-16">
    <h1 class="text-6xl text-red-500 text-center" style="color:#970000; font-family: continuo, sans-serif">Experiences</h1>
  </div>

  <v-row class="m-4 md:mx-16 lg:mx-32">
    <v-col cols="12" lg="6">
      <div class="flex flex-wrap justify-center p-4 text-center h-full box">
          <a target="blank" class="flex justify-center" href="https://apps.apple.com/kw/app/supersati/id1542151830"><img style="border-radius: 20px; width: 180px; height: 180px" alt="superSati logo" src="@/assets/ss_logo_with_txt.png" /></a>
          <div class="w-full project-title">superSati</div>
          <div class="w-full project-card-title-crafted">Crafted</div>
          <div class="w-full project-card-text">Personal Hyper Customizable Time Tracker With Achievements</div>
      </div>
    </v-col>
    <v-col cols="12" lg="6">
      <div class="flex flex-wrap justify-center p-4 text-center h-full box">
          <a target="blank" class="flex justify-center" href="https://smrk.app"><img style="border-radius: 20px; width: 180px; height: 180px" alt="smrk logo" src="@/assets/smrk_logo_text_1.png" /></a>
          <div class="w-full project-title">Smrk</div>
          <div class="w-full project-card-title-crafted">Crafted</div>
          <div class="w-full project-card-text">Us vs Them Social Platform With Personalized Avatars</div>
      </div>
    </v-col>
  </v-row>

  <v-row class="m-4 md:mx-16 lg:mx-32">
    <v-col cols="12" lg="6">
      <div class="flex flex-wrap justify-center p-4 text-center h-full box">
          <a target="blank" class="flex justify-center"><img style="border-radius: 20px; width: 180px; height: 180px" alt="glory wall logo" src="@/assets/gw.png" /></a>
          <div class="w-full project-title">gloryWall</div>
          <div class="w-full project-card-title-crafting">Crafting</div>
          <div class="w-full project-card-text">The Achievements API</div>
      </div>
    </v-col>
    <v-col cols="12" lg="6">
      <div class="flex flex-wrap justify-center p-4 text-center h-full box">
          <a target="blank" class="flex justify-center"><img style="border-radius: 20px; width: 180px; height: 180px" alt="ako logo" src="@/assets/ako.png" /></a>
          <div class="w-full project-title">AKO</div>
          <div class="w-full project-card-title-crafting">Crafting</div>
          <div class="w-full project-card-text">Request Based <br>E-Commerce</div>
      </div>
    </v-col>
  </v-row>

  <v-row class="m-4 md:mx-16 lg:mx-32">
    <v-col cols="12" lg="6">
      <div class="flex flex-wrap justify-center p-4 text-center h-full box">
          <a target="blank" class="flex justify-center"><img style="border-radius: 20px; width: 180px; height: 180px" alt="x anonymous logo" src="@/assets/Xanonymous.png" /></a>
          <div class="w-full project-title">Xanonymous</div>
          <div class="w-full project-card-title-crafting">Crafting</div>
          <div class="w-full project-card-text">Anonymous Support Groups Platform</div>
      </div>
    </v-col>
    <v-col cols="12" lg="6">
      <div class="flex flex-wrap justify-center p-4 text-center h-full box">
          <a target="blank" class="flex justify-center"><img style="border-radius: 20px; width: 180px; height: 180px" alt="CICO logo" src="@/assets/CICO.png" /></a>
          <div class="w-full project-title">CICO</div>
          <div class="w-full project-card-title-crafting">Crafting</div>
          <div class="w-full project-card-text">Simple Calories / Macros Tracker With Goals</div>
      </div>
    </v-col>
  </v-row>


  <!-- <div class="flex justify-center py-8">
      <a class="flex justify-center" href="#" @click.prevent="$router.push('/feemno')"><v-img alt="superSati logo" src="@/assets/feemno_logo.png" contain max-height="200"></v-img></a>
  </div>

  <div class="m-4 p-4">
      <h2 class="text-center text-3xl font-bold">Available Today On</h2>
  </div>

  <div class="flex justify-around pb-16 pt-4 w-full" style="border-bottom: 1px solid black">
      <a class="flex justify-center" href="https://play.google.com/store/apps/details?id=io.ionic.superSati"><v-img alt="google play logo" src="@/assets/socialMediaLogos/google-play-48.png" contain max-height="48"></v-img></a>
      <a class="flex justify-center" href="https://apps.apple.com/kw/app/supersati/id1542151830"><v-img alt="app store logo" src="@/assets/socialMediaLogos/app-store-48.png" contain height="48"></v-img></a>
  </div> -->
  <!-- <v-row>
    <v-col>
      <div class="flex justify-center p-4"> -->
          <!-- <a class="flex justify-center" href="#" @click.prevent="$router.push('/superSati')"><img style="border-radius: 20px; width: 180px" alt="superSati logo" src="@/assets/ss_logo_with_txt.png" /></a> -->
          <!-- <a target="blank" class="flex justify-center" href="https://apps.apple.com/kw/app/supersati/id1542151830"><img style="border-radius: 20px; width: 180px" alt="superSati logo" src="@/assets/ss_logo_with_txt.png" /></a>
      </div> -->

      <!-- <div class="m-4 p-4">
          <h2 class="text-center text-3xl font-bold">Available Today On</h2>
      </div> -->

      <!-- <div class="flex justify-around pb-16 pt-4 w-full" style="border-bottom: 1px solid black"> -->
          <!-- <a class="flex justify-center" href="https://supersati.com"><v-img alt="web app logo" src="@/assets/socialMediaLogos/web-48.png" contain max-height="48"></v-img></a> -->
          <!-- <a class="flex justify-center" href="https://play.google.com/store/apps/details?id=io.ionic.superSati"><v-img alt="google play logo" src="@/assets/socialMediaLogos/google-play-48.png" contain max-height="48"></v-img></a> -->
          <!-- <a class="flex justify-center" href="https://apps.apple.com/kw/app/supersati/id1542151830"><v-img alt="app store logo" src="@/assets/socialMediaLogos/app-store-48.png" contain height="48"></v-img></a> -->
          <!-- <a class="flex justify-center" href="https://www.facebook.com/superSati.selfDev/"><v-img alt="facebook logo" src="@/assets/socialMediaLogos/facebook-48.png" contain max-height="48"></v-img></a> -->
          <!-- <a class="flex justify-center" href="https://www.instagram.com/super.sati/"><v-img alt="instagram logo" src="@/assets/socialMediaLogos/instagram-48.png" contain max-height="48"></v-img></a> -->
      <!-- </div> -->
    <!-- </v-col>
    <v-col>
      <div class="flex justify-center p-4"> -->
          <!-- <a class="flex justify-center" href="#" @click.prevent="$router.push('/superSati')"><img style="border-radius: 20px; width: 180px" alt="superSati logo" src="@/assets/cybrvrs_logo_180.png" /></a> -->
          <!-- <a target="blank" class="flex justify-center" href="https://cybrvrs.com"><img style="border-radius: 20px; width: 180px" alt="superSati logo" src="@/assets/cybrvrs_logo_180.png" /></a>
      </div> -->

      <!-- <div class="m-4 p-4">
          <h2 class="text-center text-3xl font-bold">Available Today On</h2>
      </div> -->

      <!-- <div class="flex justify-around pb-16 pt-4 w-full" style="border-bottom: 1px solid black"> -->
          <!-- <a class="flex justify-center" href="https://supersati.com"><v-img alt="web app logo" src="@/assets/socialMediaLogos/web-48.png" contain max-height="48"></v-img></a> -->
          <!-- <a class="flex justify-center" href="https://play.google.com/store/apps/details?id=io.ionic.superSati"><v-img alt="google play logo" src="@/assets/socialMediaLogos/google-play-48.png" contain max-height="48"></v-img></a> -->
          <!-- <a class="flex justify-center" href="https://apps.apple.com/kw/app/supersati/id1542151830"><v-img alt="app store logo" src="@/assets/socialMediaLogos/app-store-48.png" contain height="48"></v-img></a> -->
          <!-- <a class="flex justify-center" href="https://www.facebook.com/superSati.selfDev/"><v-img alt="facebook logo" src="@/assets/socialMediaLogos/facebook-48.png" contain max-height="48"></v-img></a> -->
          <!-- <a class="flex justify-center" href="https://www.instagram.com/super.sati/"><v-img alt="instagram logo" src="@/assets/socialMediaLogos/instagram-48.png" contain max-height="48"></v-img></a> -->
      <!-- </div> -->
    <!-- </v-col>
  </v-row> -->
 
  <contact-us title="<h1 style='color: #970000'>Contact Us</h1>" text="If you have any inquiries, technical issue or feedback on any of our products <br> please do not hesitate to contact us through the following"/>

  <!-- <div class="mt-8 flex justify-center py-4">
    <h1 class="text-3xl text-red-500 text-center">Still Crafting</h1>
  </div>

  <div class="flex justify-around align-center py-8 text-center font-bold text-4xl">

      <a class="w-1/2" href="#" @click.prevent="$router.push('/superSati')"><v-img alt="superSati logo" src="@/assets/superSati_logo.png" contain max-height="200"></v-img></a>
      <a class="w-1/2" href="#" @click.prevent="$router.push('/superSati')"><v-img alt="superSati logo" src="@/assets/superSati_logo.png" contain max-height="200"></v-img></a>
      
      <div class="w-1/2">
        <h2 class="font-bold text-4xl">Zelafi</h2>
        <p class="text-sm">Have you ever been chatting with someone <br>and you just wanted to slap them<br> Batman/Robin style?</p>
      </div>
      <div class="w-1/2">
        <h2 class="font-bold text-4xl">World<br>of<br>BssK</h2>
        <p class="text-sm">Asynchronous MMORPG</p>
      </div>

  </div> -->
</div>
</template>

<script>
import ContactUs from '@/views/ContactUs.vue'

export default {
  name: 'Home',
  components: {
    ContactUs
  }
}
</script>

<style>

.card{
  box-shadow: 0px 0px 5px #00000040;
  padding: 20px;
  border-radius: 20px;
  margin-top: 10px;
}

.project-card-title-crafted{
  color:#00b690; 
  font-family: continuo, sans-serif; 
  font-size: 48px
}
.project-card-title-crafting{
  color:#970000; 
  font-family: continuo, sans-serif; 
  font-size: 48px
}
.project-card-text{
  font-family: bc-alphapipe, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 32px;
  color: #5a5a5a;
}

body p{
  font-family: bc-alphapipe, sans-serif;
  color: #5a5a5a;
  font-size: 24px;
}

.project-title{
  font-family: bc-alphapipe, sans-serif;
  font-size: 24px;
  color:#00b690; 
}

.box{
  box-shadow: 0px 0px 5px #00000040, inset 0px 0px 5px #00000040; 
  border-radius: 50px; 
  border: 7px solid white
}

</style>