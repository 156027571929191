<template>
<div class="mt-16">

  <div class="home flex justify-center m-8">

    <v-img alt="superSati logo" contain max-height="200" src="@/assets/feemno_logo.png"></v-img>

  </div>

  <div class="m-4 p-4">
      <h2 class="text-center text-3xl font-bold">Available Today On The Web</h2>
  </div>

  <!-- <div class="flex justify-center  w-full">
      <a class="flex justify-center" href="https://play.google.com/store/apps/details?id=io.ionic.superSati"><v-img alt="google play logo" src="@/assets/socialMediaLogos/google-play-48.png" contain max-height="48"></v-img></a>
      <a class="flex justify-center" href="https://apps.apple.com/kw/app/supersati/id1542151830"><v-img alt="app store logo" src="@/assets/socialMediaLogos/app-store-48.png" contain height="48"></v-img></a>
  </div> -->

  <div class="p-8 text-center">

        <h2 class="text-4xl text-center pb-4">You need a product from local stores and dont have the time to go through a 100 stores or simply don't know where to look?</h2>
        <p class="text-3xl text-center pb-4">
            Just Submit what you need and we will look for it for you.
        </p>
        <p class="text-3xl text-center pb-4">
            We will get back to you with the best offers in the local stores
        </p>
            
  </div>

  <contact-us></contact-us>

</div>
</template>

<script>
import ContactUs from '@/views/ContactUs.vue'

export default {
  name: 'Cybrvrs',
  components: {
    ContactUs
  }
}
</script>
