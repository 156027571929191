<template>
<div class="mt-16">

  <div class="home flex justify-center m-8">

    <v-img alt="superSati logo" contain max-height="200" src="@/assets/superSati_logo.png"></v-img>

  </div>

  <div class="m-4 p-4">
      <h2 class="text-center text-3xl font-bold">Available Today On</h2>
  </div>

  <div class="flex justify-center  w-full">
      <a class="flex justify-center" href="https://supersati.com"><v-img alt="web app logo" src="@/assets/socialMediaLogos/web-48.png" contain max-height="48"></v-img></a>
      <a class="flex justify-center" href="https://play.google.com/store/apps/details?id=io.ionic.superSati"><v-img alt="google play logo" src="@/assets/socialMediaLogos/google-play-48.png" contain max-height="48"></v-img></a>
      <a class="flex justify-center" href="https://apps.apple.com/kw/app/supersati/id1542151830"><v-img alt="app store logo" src="@/assets/socialMediaLogos/app-store-48.png" contain height="48"></v-img></a>
      <a class="flex justify-center" href="https://www.facebook.com/superSati.selfDev/"><v-img alt="facebook logo" src="@/assets/socialMediaLogos/facebook-48.png" contain max-height="48"></v-img></a>
      <a class="flex justify-center" href="https://www.instagram.com/super.sati/"><v-img alt="instagram logo" src="@/assets/socialMediaLogos/instagram-48.png" contain max-height="48"></v-img></a>
  </div>

  <div class="p-8 text-center">

    <h2 class="text-4xl text-center pb-4">An ecosystem that puts you in the right mindset to make your dreams a reality</h2>
        <p>
            You have decided to make a certain change in your life, may it be learning a new skill or tackling a new project or simply losing weight, superSati provides you with a framework and tools to track and evaluate your progress and the resources to help you at least get started on making this decision a reality.
        </p>
        <p>
            The main element is Time. What you spend your time on is what makes you who you are. superSati helps you be more mindful and conscious of how you spend your time and the value of it and to invest it in what really matters to you while being a part of an ambitious community that it’s members are thriving to achieve a better more meaningful lives. 
        </p>
            <h3 class="text-2xl font-bold text-center pb-4">What superSati isn’t</h3>
        <p>
            At first glance superSati is just a simple task manager and time tracker with a couple of extra features, the reality is that it is not meant to remind you to buy the milk or manage a business project, but to only be used to track what’s contributing to your personal goals and dreams.
        </p>
            <h3 class="text-2xl font-bold text-center pb-4">Project VS Skill</h3>
        <p>
            Project: What are you working on as a whole, as an example ‘Writing a novel’.
            Skill: The skill that you enhance and progress when you perform its tasks, as an example ‘Writing’
            Task: The particular task at hand that you are working on, as an example ‘Write the first chapter’
        </p>
        <p>
            Tasks can have different combinations of project and skill, following the example, another task can be ‘Research a historical character’ also under Project ‘Writing a novel’ but it’s Skill would be ‘Research’ or if you are writing a business document this can have the same Skill of ‘Writing’ but under a different Project.
        </p>
        <p>
            Each task needs to have a Project and a Skill, the What and the Why, and for each task you track the time you spend on it, that time contributes to the total time spent on the totals of the project and the skill. this is an exceptional way to view the value of your efforts and always be mindful of your what and why.
        </p>
            <h3 class="text-2xl font-bold text-center pb-4">How to form habits & keep yourself motivated</h3>
        <p>
            Another great feature is Challenges. It is a different approach to traditional habit trackers which almost always makes the assumption that a habit starts and never ends and the best measure is the streaks which also contribute to another terrible side effect, the longer it goes the less meaningful it becomes, it just becomes an automatic action of logging your habits everyday and with a long enough period you will eventually just stop. superSati Challenges approach is setting for yourself a fixed short period in which you track your habit on, the period should be long enough that the habit has already become an automatic action for you then challenge ends, and you repeat or tweak a challenge as you want and as much as need. 
        </p>
        <p>
            An example of a challenge would be ‘Write everyday for 7 days’ doesn’t matter how long you spend as time, or it could be ‘ write everyday for an hour for 7 days’ which sets a minimum of time you set for yourself. There are many ways for you to track exactly what you need including even the need to manually record your progress or to get recorded automatically.
        </p>
        <p>
            With that approach you put your mindset in an active state for a certain period of time, you are focused, you have a start and an end, like a race, if there is no finish line eventually you will just stop, but seeing the end makes you push yourself to the finish line and sense your achievement.
        </p>
            <h3 class="text-2xl font-bold text-center pb-4">The Codex, the Feed & The Social</h3>
        <p>
            Now for the goals and dreams themselves, superSati tracking tools are just a part of the ecosystem, the Codex, which is just a fancy name for a knowledge base, grows by the day with new resources you can use in your journey. The Codex will introduce you to books, youtube channels, documentaries, online and irl communities, events and activities, and as the superSati community contributions grows so that the Codex.
        </p>
        <p>
            The Feed is an in app posts feed that will show you new resources being added to the Codex and original content to motivate you and open your mind to new ideas.
        </p>
        <p>
            Our Facebook and Instagram pages are an integral part of superSati and not just marketing facades for the app, they includes the content of the feed and are the place where the superSati community lives. Meet new people with their own goals and challenges and exchange experiences and knowledge with each other and with us, any new relevant and meaningful knowledge, or a local event can be proposed to superSati team to be included in the Codex and the Feed.
        </p>
        <p>
            Use all of the features or only a set of it, its your choice, in all cases we at superSati are working hard at providing you with everything you need to achieve your goals and dreams.
        </p>
        <h3 class="text-2xl font-bold text-center pb-4">Have a happy, meaningful & productive day superSatian!</h3>
  </div>


  <div class="pb-8 m-8">
    <h2 class="text-4xl text-center p-4 text-red-500">The superSati self development framework</h2>

    <p class="text-center text-2xl font-bold" style="border: 1px solid black; border-radius: 20px">Create the Skill you want to develop</p>
    
    <p class="text-center text-2xl font-bold" style="border: 1px solid black; border-radius: 20px">Create the Project which working on will develop that skill</p>
    
    <p class="text-center text-2xl font-bold" style="border: 1px solid black; border-radius: 20px">Add time spent on them or create Tasks and track time while you are working</p>
    
    <p class="text-center text-2xl font-bold" style="border: 1px solid black; border-radius: 20px">See your daily, current week, past three months progress on the dashboard to stay conscious and mindful about your progress</p>
    
    <p class="text-center text-2xl font-bold" style="border: 1px solid black; border-radius: 20px">Set periodic challenges for yourself to keep yourself engaged and motivated and earn achievements</p>
    
    <p class="text-center text-2xl font-bold" style="border: 1px solid black; border-radius: 20px">Discover useful resources that help you learn new information</p>
  </div>

  <div class="pb-8 m-8">
    <h2 class="text-4xl text-center p-4 text-red-500">Features Set</h2>

    <p class="text-center text-2xl font-bold">Create Skills, Projects and tasks to track your work and your progress</p>
    
    <p class="text-center text-2xl font-bold">Log and track the time spent on Skills and Projects</p>
    
    <p class="text-center text-2xl font-bold">See your progress in charts on the dashboard</p>
    
    <p class="text-center text-2xl font-bold">Set challenges to motivate yourself and earn achievements</p>
        
    <p class="text-center text-2xl font-bold">Discover useful resources that help you learn new information</p>
  </div>



  <div style="border-top: 1px solid black; border-bottom: 1px solid black" class="pb-8">
    <h2 class="text-4xl text-center p-4 text-red-500">App How To</h2>
    <h3 class="text-2xl font-bold text-center pb-4">Feed & Codex</h3>

    <div class="flex flex-wrap justify-center">
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:100px">On opening the app you will be met by the Feed & codex which you can use without registration</p>
            <v-img src="@/assets/tutorialimgs/feed.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:100px">The Codex is where you will find the rsources to help you on your self development journey</p>
            <v-img src="@/assets/tutorialimgs/codex.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:100px">You can filter the codex with the topic you are interested in</p>
            <v-img src="@/assets/tutorialimgs/topics.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
    </div>

    <h3 class="text-2xl font-bold text-center pb-4">Register</h3>

    <div class="flex flex-wrap justify-center">
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:50px">Open side menu and tap register</p>
            <v-img src="@/assets/tutorialimgs/unlogged_side_menu.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:50px">Fill the registration form</p>
            <v-img src="@/assets/tutorialimgs/registration_form.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:50px">Time is everything</p>
            <v-img src="@/assets/tutorialimgs/tutorial_1.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:50px">The features</p>
            <v-img src="@/assets/tutorialimgs/tutorial_2.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:50px">The What & The Why</p>
            <v-img src="@/assets/tutorialimgs/tutorial_3.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:50px">Fill your starte data</p>
            <v-img src="@/assets/tutorialimgs/tutorial_starter_data.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
    </div>

    <h3 class="text-2xl font-bold text-center pb-4">Login</h3>

    <div class="flex flex-wrap justify-center">
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:50px">Open side menu and tap login</p>
            <v-img src="@/assets/tutorialimgs/unlogged_side_menu.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:50px">Enter your credentials</p>
            <v-img src="@/assets/tutorialimgs/registration_form.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
    </div>

    <h3 class="text-2xl font-bold text-center pb-4">Profile</h3>

    <div class="flex flex-wrap justify-center">
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:100px">Open side menu and tap Profile</p>
            <v-img src="@/assets/tutorialimgs/loggedin_menu.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px; height:100px">In your profile you can change your name, App color theme, and dark or light mode</p>
            <v-img src="@/assets/tutorialimgs/profile.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
    </div>

    <h3 class="text-2xl font-bold text-center pb-4">Dashboard</h3>

    <div class="flex flex-wrap justify-center">
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:250px">See your progress throughout the day and week</p>
            <v-img src="@/assets/tutorialimgs/dashboard_1.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:250px">See your progress for today in terms of project</p>
            <v-img src="@/assets/tutorialimgs/dashboard_2.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:250px">See your progress for today in terms of skills</p>
            <v-img src="@/assets/tutorialimgs/dashboard_3.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:250px">See your progress for current week in terms of project</p>
            <v-img src="@/assets/tutorialimgs/dashboard_4.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:250px">See your progress for current week in terms of skills</p>
            <v-img src="@/assets/tutorialimgs/dashboard_5.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:250px">See your progress throughout the days of the week</p>
            <v-img src="@/assets/tutorialimgs/dashboard_6.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:250px">See your progress throughout the past 16 weeks / 3 months</p>
            <v-img src="@/assets/tutorialimgs/dashboard_7.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:250px">See your progress throughout the past 16 weeks / 3 months</p>
            <v-img src="@/assets/tutorialimgs/dashboard_8.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
    </div>

    <h3 class="text-2xl font-bold text-center pb-4">Tasks</h3>
    <p class="text-2xl text-center">Status:</p>
    <p class="text-xl text-center"><strong>Focus:</strong> What you are working on right now, the most important</p>
    <p class="text-xl text-center"><strong>ToDo:</strong> Everything that you need to do</p>
    <p class="text-xl text-center"><strong>Backlog:</strong> What you may one day do</p>
    <p class="text-xl text-center"><strong>Done:</strong> Your accomplished tasks</p>

    <div class="flex flex-wrap justify-center">
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px">See & manage your tasks</p>
            <v-img src="@/assets/tutorialimgs/tasks.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px">Change task Status</p>
            <v-img src="@/assets/tutorialimgs/change_task_status.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px">Edit / Delete Task</p>
            <v-img src="@/assets/tutorialimgs/edit_delete_task.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
    </div>
    

    <h3 class="text-2xl font-bold text-center pb-4">Notes</h3>

    <div class="flex flex-wrap justify-center">
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px">See & manage your Notes</p>
            <v-img src="@/assets/tutorialimgs/notes.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
    </div>

    <h3 class="text-2xl font-bold text-center pb-4">Challenges</h3>

    <div class="flex flex-wrap justify-center">
        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px">See & manage your challenges</p>
            <v-img src="@/assets/tutorialimgs/challenges.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>

        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px">See & manage your achievements</p>
            <v-img src="@/assets/tutorialimgs/achievements.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>

        <div class="m-2 p-2">
            <p class="text-center" style="max-width:200px">See & manage your completed challenges</p>
            <v-img src="@/assets/tutorialimgs/completed_challenges.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
    </div>

    <h3 class="text-4xl font-bold text-center pb-4">Add New</h3>

    <div class="flex flex-wrap justify-center">

    
        <div class="m-2 p-2">
            <h4 class="text-xl font-bold text-center pb-4">Project - Skill - Action</h4>
            
            <v-img src="@/assets/tutorialimgs/category_form.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>


    
        <div class="m-2 p-2">
            <h4 class="text-xl font-bold text-center pb-4">Challenge</h4>
            
            <v-img src="@/assets/tutorialimgs/challenge_form.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>


    
        <div class="m-2 p-2">
            <h4 class="text-xl font-bold text-center pb-4">Task</h4>
            
            <v-img src="@/assets/tutorialimgs/task_form.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>


    
        <div class="m-2 p-2">
            <h4 class="text-xl font-bold text-center pb-4">Note</h4>
            
            <v-img src="@/assets/tutorialimgs/note_form.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>


    
        <div class="m-2 p-2">
            <h4 class="text-xl font-bold text-center pb-4">Action</h4>
            
            <v-img src="@/assets/tutorialimgs/action_form.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>


    
        <div class="m-2 p-2">
            <h4 class="text-xl font-bold text-center pb-4">Time</h4>
            
            <v-img src="@/assets/tutorialimgs/time_form.png" max-width="200" style="border: 1px solid black; border-radius:20px"></v-img>
        </div>
    </div>
  </div>

  <contact-us></contact-us>

</div>
</template>

<script>
import ContactUs from '@/views/ContactUs.vue'

export default {
  name: 'SuperSati',
  components: {
    ContactUs
  }
}
</script>
