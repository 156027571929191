<template>
  <div class="mb-12">

    
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      left
    >
        <v-list shaped>

          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            :to="item.link"
            link
            >
            <v-list-item-icon>
                <!-- <v-icon small>{{ item.icon }}</v-icon> -->
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title style="color: black" class="flex text-4xl">{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            
        </v-list>
    </v-navigation-drawer>
    
    <v-app-bar color="black" fixed>
    <!-- <v-app-bar color="#0ed9b0" fixed> -->
     <!-- <V-toolbar-title class="w-full"><router-link to="/" class="font-extrabold" style="color: #ffffff !important;"><span style="color:black">mint</span>ality</router-link></V-toolbar-title> -->
     <V-toolbar-title class="w-full"><router-link to="/" class="font-extrabold" style="color: #ffffff !important;">LCS</router-link></V-toolbar-title>

      <!-- <v-spacer></v-spacer> -->
        <span class="hidden-sm-and-up">
            <v-btn rounded color="white"  @click.stop="drawer = !drawer">
            <v-icon>mdi-menu</v-icon>
            </v-btn>
        </span>
        <v-toolbar-items class="hidden-xs-only flex justify-end w-full">

          <v-btn text v-for="item in menuItems"
            :key="item.title"
            :to="item.link"
            color="white"
            link>
              
              <!-- <v-icon small left>{{item.icon}}</v-icon> -->
              {{ item.title }} 
          </v-btn>
          <!-- <v-btn text @click="$store.state.lang == 'en' ? $store.state.lang = 'ar' : $store.state.lang = 'en'" color="white" >{{ $store.state.lang == 'en' ? 'العربية' : 'English'}}</v-btn> -->

        </v-toolbar-items>

    </v-app-bar>


  </div>
</template>

<script>

export default {
  
  name:'MainMenu',

  props:[
  ],

  components:{
    
  },

  data() {
    return {
      drawer: false,
    }
  },

  computed:{
    t(){
    if(this.$store.state.lang == 'en'){
        return {}
        }else {
          return {}
        }
    },

    menuItems(){
      return [
        // { title: 'feemno', link: '/feemno', icon: 'how_to_reg' },
        // { title: 'CYBRVRS', link: '/cybrvrs', icon: 'how_to_reg' },
        // { title: 'superSati', link: '/superSati', icon: 'how_to_reg' },
        { title: this.$store.state.lang == 'en' ? 'contact' : 'تواصلوا معنا', link: '/contactUs', icon: 'how_to_reg' },
      ]

    }
  },

}

</script>