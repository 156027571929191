<template>
  <v-app class="mx-auto overflow-hidden">
    <!-- <main-menu /> -->

    <v-main class="mb-20">
      <router-view :key="$route.fullPath" class="mx-auto"/>
    </v-main>

    

    <v-footer absolute style="color: white" class="font-weight-medium" :style="$route.name == 'Mintality' ? {background: '#0ed9b0'} : {background: '#970000'}">
      <v-col class="text-center" cols="12" >
         <strong>Copyright © {{ new Date().getFullYear() }} LegionCraftStudios - All Rights Reserved</strong>
      </v-col>
    </v-footer>

  </v-app>
</template>

<script>
import MainMenu from '@/components/menus/MainMenu.vue'
export default {
  name: 'App',

  components: {
    MainMenu
  },

  data: () => ({
    //
  }),
};
</script>
