import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Services from '../views/Services.vue'
import ContactUs from '../views/ContactUs.vue'
import SuperSati from '../views/SuperSati.vue'
// import Feemno from '../views/Feemno.vue'
import Cybrvrs from '../views/Cybrvrs.vue'
import Mintality from '../views/Mintality.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/services', name: 'Services', component: Services,
    meta: { title: 'Services' }
  },
  {
    path: '/', name: 'Home', component: Home,
    meta: { title: 'Home Page' }
  },
  {
    path: '/mintality', name: 'Mintality', component: Mintality,
    meta: { title: 'mintality' }
  },
  // {
  //   path: '/shuffle', name: 'Shuffle', component: Shuffle,
  //   meta: { title: 'shuffle' }
  // },
  {
    path: '/cybrvrs', name: 'Cybrvrs', component: Cybrvrs,
    meta: { title: 'cybrvrs' }
  },
  // {
  //   path: '/feemno', name: 'Feemno', component: Feemno,
  //   meta: { title: 'feemno' }
  // },
  {
    path: '/superSati', name: 'SuperSati', component: SuperSati,
    meta: { title: 'superSati' }
  },
  {
    path: '/contactUs', name: 'Contact Us', component: ContactUs,
    meta: { title: 'Contact Us' }
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
