<template>
<div>
  <!-- <div class="home flex justify-center align-center" style="background-color: #0000000d; height: 400px">
      <div>
        <v-img alt="legion craft studios logo" src="@/assets/mintLogo.png" contain max-height="300"></v-img>
      </div>
      <div>
        <p style="max-width: 300px; text-align: center">A team of young talented lunatics who are based in four different countries and work together to cover your marketing needs the way you aim for.</p>
      </div>
  </div> -->
  <v-row class="home flex justify-center align-center" style="margin: 0px; height: 500px; background-size: cover" :style="{ backgroundImage: 'url(' + require('@/assets/lightBulb.jpeg') + ')' }">
      <v-col class="home flex justify-center align-center">
          <div>
            <v-img class="floating" alt="legion craft studios logo" src="@/assets/mintLogo.png" contain max-height="300"></v-img>
          </div>
      </v-col>
      <v-col class=" home flex justify-center align-center">
        <p class="slide-left" style="max-width: 400px; text-align: center; font-weight: 700; background: #ffffffe6; padding: 10px; border-radius: 20px;">{{t.whoWeAre.body}}</p>
      </v-col>
  </v-row>
  <v-row>
        <v-carousel
    cycle
    interval="3000"
    height="400"
    hide-delimiter-background
    show-arrows-on-hover
  >
    <v-carousel-item
      v-for="slide in slides"
      :key="slide.color"
    >
      <v-sheet
        :color="slide.color"
        height="100%"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <div v-html="slide.text" class="text-center" style="font-size: 5vw">
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
  </v-row>
    <v-container>

    <v-row class="p-8 slide-left" style="position: relative">
        <div v-if="vpWidth > 960" style="position: absolute; top: 20%; left: 10%; background: linear-gradient(90deg, #0000000d, #00000000); width: 100%; height: 50%; z-index: 0"></div>
        <v-col cols="12" md="4" lg="4">
            <div>
                <v-img class="floating" style="animation-delay: 1s"  alt="legion craft studios logo" src="@/assets/iphone.png" contain max-height="500"></v-img>
            </div>
        </v-col>
        <v-col cols="12" md="8" lg="8">
            <div class="text-box" style="position: relative; top: 20%">
                <h2 class="text-title">{{t.socialMedia.title}}</h2>
                <p class="text-body" v-html="t.socialMedia.body"></p>
            </div>
        </v-col>
    </v-row>
    <v-row class="p-8 slide-right" style="position: relative">
        <div v-if="vpWidth > 960" style="position: absolute; top: 20%; right: 10%; background: linear-gradient(90deg, #00000000, #0000000d); width: 100%; height: 50%; z-index: 0"></div>
        <v-col cols="12" md="8" lg="8">
            <div class="text-box" style="position: relative; top: 20%">
                <h2 class="text-title">{{t.creativeDesign.title}}</h2>
                <p class="text-body" v-html="t.creativeDesign.body"></p>
            </div>
        </v-col>
        <v-col cols="12" md="4" lg="4">
            <div>
                <v-img class="floating"  style="animation-delay: 1.5s" alt="legion craft studios logo" src="@/assets/surface.png" contain max-height="500"></v-img>
            </div>
        </v-col>
    </v-row>
    <v-row class="p-8 slide-left" style="position: relative">
        <div v-if="vpWidth > 960" style="position: absolute; top: 20%; left: 10%; background: linear-gradient(90deg, #0000000d, #00000000); width: 100%; height: 50%; z-index: 0"></div>
        <v-col cols="12" md="4" lg="4">
            <div>
                <v-img class="floating"  style="animation-delay: 2s" alt="legion craft studios logo" src="@/assets/imac.png" contain max-height="500"></v-img>
            </div>
        </v-col>
        <v-col cols="12" md="8" lg="8">
            <div class="text-box" style="position: relative; top: 20%">
                <h2 class="text-title">{{t.webDevelopment.title}}</h2>
                <p class="text-body" v-html="t.webDevelopment.body"></p>
            </div>
        </v-col>
    </v-row>
    <v-row class="p-8 slide-right" style="position: relative">
        <div v-if="vpWidth > 960" style="position: absolute; top: 20%; right: 10%; background: linear-gradient(90deg, #00000000, #0000000d); width: 100%; height: 50%; z-index: 0"></div>
        <v-col cols="12" md="8" lg="8">
            <div class="text-box" style="position: relative; top: 20%">
                <h2 class="text-title">{{t.digitalMarketing.title}}</h2>
                <p class="text-body" v-html="t.digitalMarketing.body"></p>
            </div>
        </v-col>
        <v-col cols="12" md="4" lg="4">
            <div>
                <v-img class="floating"  style="animation-delay: 0.5s" alt="legion craft studios logo" src="@/assets/flyers.png" contain max-height="500"></v-img>
            </div>
        </v-col>
    </v-row>

    <v-row class="justify-center p-4">
        <v-col cols="12" md="3" lg="3">
            <div class="price-tier-card" style="border-radius: 20px; text-align: center">
                <div class="button-bronze price-tier-card-header">Bronze</div>
                <div>Website</div>
                <div>Domain</div>
                <div>Email</div>
                <div>1 year support</div>
                <div class="d-flex justify-center">
                    <div class="select-tier-btn">750 KD</div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" md="3" lg="3">
            <div id="offer" class="price-tier-card" style="border-radius: 20px; text-align: center">
                <div class="button-gold price-tier-card-header">Gold</div>
                <div>Website</div>
                <div>Domain</div>
                <div>Email</div>
                <div>iOS App</div>
                <div>Android App</div>
                <div>1 year priority support</div>
                <div class="d-flex justify-center">
                    <div class="select-tier-btn"><span style="color: gray; text-decoration: line-through">1500</span>1000 KD</div>
                </div>
            </div>
        </v-col>
        <v-col cols="12" md="3" lg="3">
            <div class="price-tier-card" style="border-radius: 20px; text-align: center">
                <div class="button-platinum price-tier-card-header">Platinum</div>
                <div>Website</div>
                <div>Domain</div>
                <div>Email</div>
                <div>iOS App</div>
                <div>Android App</div>
                <div>Branding</div>
                <div>1 year priority support</div>
                <div class="d-flex justify-center">
                    <div class="select-tier-btn">2000 KD</div>
                </div>
            </div>
        </v-col>
    </v-row>
    
    <v-row>
        Detailed offering:

        Website: 
        -5 static pages
        -images/videos/text delivered by client
        
        Domain:
        -1 year domain name subscription and management

        Email:
        -1 year 1 Gmail/Office 365 account management (each extra email costs 5kd/month)

        iOS /Android:
        -depending on your requirement you can choose from the following starter apps and get 5 free extra features:
            -Shopping
            -Food delivery
            -Restaurant menu
            -restaurant reservation
            -blog
            -social network


    </v-row>
    
    <!-- <v-row class="justify-center p-4">
        <v-col cols="12" md="3" lg="3">
            <div class="flip-card">
                <div class="flip-card-inner">
                    <div class="flip-card-front">
                        <div class="price-tier-card" style="border-radius: 20px; text-align: center">
                        <div class="button-bronze price-tier-card-header">Silver</div>
                        <div>Website</div>
                        <div>Domain</div>
                        <div>Email</div>
                        <div>1 year support</div>
                        <div class="select-tier-btn">750 KD</div>
                    </div>
                    </div>
                    <div class="flip-card-back">
                    <h1>John Doe</h1> 
                    <p>Architect & Engineer</p> 
                    <p>We love that guy</p>
                    </div>
                </div>
            </div>
            
        </v-col>
        <v-col cols="12" md="3" lg="3">
            <div id="offer" class="price-tier-card" style="border-radius: 20px; text-align: center">
                <div class="button-gold price-tier-card-header">Gold</div>
                <div>Website</div>
                <div>Domain</div>
                <div>Email</div>
                <div>iOS App</div>
                <div>Android App</div>
                <div>1 year priority support</div>
                <div class="select-tier-btn"><span style="color: gray; text-decoration: line-through">1500</span>1000 KD</div>
            </div>
        </v-col>
        <v-col cols="12" md="3" lg="3">
            <div class="price-tier-card" style="border-radius: 20px; text-align: center">
                <div class="button-platinum price-tier-card-header">Platinum</div>
                <div>Website</div>
                <div>Domain</div>
                <div>Email</div>
                <div>iOS App</div>
                <div>Android App</div>
                <div>Branding</div>
                <div>1 year priority support</div>
                <div class="select-tier-btn">2000 KD</div>
            </div>
        </v-col>
    </v-row> -->

    </v-container>
  
</div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    components: {
        
    },

    // data () {
    //   return {
    //     colors: [
    //       'indigo',
    //       'warning',
    //       'pink darken-2',
    //       'red lighten-1',
    //       'deep-purple accent-4',
    //     ],
    //     slides: [
    //       'First',
    //       'Second',
    //       'Third',
    //       'Fourth',
    //       'Fifth',
    //     ],
    //   }
    // },

    computed: {

        slides() {
            if(this.$store.state.lang == 'en'){
                return [
                    {text: 'Have an idea for an app and do not know where to start', color: 'indigo'},
                    {text: 'Online business is not just an app', color: 'warning'},
                    {text: 'We are your business consultants first', color: 'pink darken-2'},
                    {text: 'Afraid of notdelivering on time?<br> Continuous delivery will put your mind at ease', color: 'red lighten-1'},
                    {text: 'Your success story here :)', color: 'deep-purple accent-4'}
                ]
            } else {
                return [
                    {text: 'بدك تسوي تطبيق ببالك و مو تعرف وين تبدأ', color: 'indigo'},
                    {text: 'العمل الرقمي مو مجرد تطبيق', color: 'warning'},
                    {text: 'نحن في المقام الاول مستشاريك', color: 'pink darken-2'},
                    {text: 'خائف من عدم التسليم في الميعاد؟<br> التسليم المتواصل يريح بالك', color: 'red lighten-1'},
                    {text: 'قصة نجاحك هنا :)', color: 'deep-purple accent-4'}
                ]
            }
        },

        height () {
            switch (this.$vuetify.breakpoint.name) {
            case 'xs': return 220
            case 'sm': return 400
            case 'md': return 500
            case 'lg': return 600
            case 'xl': return 800
            }
        },

        vpWidth(){
            return this.$vuetify.breakpoint.width
        },

        t(){
            if(this.$store.state.lang == 'en'){
                return {
                    whoWeAre:{
                        title: '',
                        body: `A multinational team of young talented business developers based in four different countries and work together to cover your online buiness needs the way you aim for.`
                    },
                    socialMedia:{
                        title: 'SOCIAL MEDIA MARKETING',
                        body: `We have the capable marketing minds who can integrate your social media content and communication with a complete marketing strategy.
                            Team mint covers all your needs for photography, videography, content plans, creative designs, in addition to the day to day management and monitoring your accounts on Instagram, Facebook, Twitter, and Linkedin.

                            Contact us to learn more about our new monthly packages!`
                    },
                    creativeDesign:{
                        title: `CREATIVE DESIGN`,
                        body: `Ever noticed that among thousands of nice artworks only few can be remembered?

                            Creative design is more than picking the right paints, font, and colors; It is communicating a marketing message loudly and clearly.

                            Team mint has Adobe certified professionals with years of branding and advertising experience who can craft your awesome artworks when their skills and talents meet marketing and design principles.`
                    },
                    webDevelopment:{
                        title: `Web Development`,
                        body: `Your online presence starts with your business' website.

                            Team mint designs and builds your website with your business goals in mind, we think of your website as "conversion machine" that turns visitors into customers.

                            Your mint-designed website is nice looking, user-friendly, easy to manage, and equipped with advanced communication, advertising, and data management tools.`
                    },
                    digitalMarketing:{
                        title: `Digital Marketing`,
                        body: `Complete digital marketing strategies integrate your online touchpoints including digital advertising and organic content on your owned media.

                            Team mint's Google Ads certified professional can efficiently run your digital advertising campaigns on all platforms: Instagram, Snapchat, Google Display, Search, YouTube and more!

                            Contact us today and reach hundreds of thousands of your potential customers!`
                    },
                                        

                }
            }
            else{
                return {
                    whoWeAre:{
                        title: `إدارة مواقع التواصل الاجتماعي`,
                        body: `فريق متعدد الجنسيات من مطوري الأعمال الموهوبين الشباب المقيمين في أربعة بلدان مختلفة ويعملون معًا لتغطية احتياجات عملك الرقمي بالطريقة التي تهدف إليها.`
                    },
                    socialMedia:{
                        title: `إدارة مواقع التواصل الاجتماعي`,
                        body: `لدينا فريق تسويق متخصص يقوم بتقييم مجال العمل الخاص بك و دراسة مبدئية للسوق المحلي للتركيز على نقاط القوة ووضع استراتيجية تسويقية لكل فترة زمنية.
                            فريق عمل منت يغطي جميع احتياجاتك في مجال إدارة مواقع التواصل الاجتماعي من التصوير الفوتوغرافي ، تصوير فيديو ، عمل الخطط الأسبوعية للحسابات ، التصاميم الاحترافية بالإضافة الى متابعة و إدارة الحسابات بشكل يومي مع وضع خطة لإدارة المخاطر.
                            يمكننا إدارة حساباتك على موقع فيس بوك، انستقرام ، تويتر و لينك ان.

                            تواصل معنا لاستشارة مجانية وللتعلم أكثر عن إدارة حسابات التواصل الاجتماعي.`
                    },
                    creativeDesign:{
                        title: `التصاميم الاحترافية`,
                        body: `هل عمرك لاحظت أنه من بين آلاف التصاميم الجيدة قليلا منها ما يتم تذكره؟ 

                            التصميم الاحترافي ليس مجرد ألوان و خطوط التصميم عبارة عن وسيلة تسويقية للاتصال بالعميل بطريقة و رسالة واضحة و معبرة.
                            فريق عمل منت مرخص من قبل شركة أدوبي للتصميم مع سنوات خبرة في مجال العلامات التجارية والاعلانات بالعمل مع فريق التسويق لتوصيل رسالتك عبر التصميم بطريقة احترافية وصحيحة.`
                    },
                    webDevelopment:{
                        title: `بناء و تطوير المواقع الالكترونية `,
                        body: `نافذتك إلى عالم الديجتال يبدأ من موقعك الالكتروني 

                            فريق عمل منت يصمم ويطور موقعك الالكتروني بناء على اهداف الشركة الخاص بك. نحن نفكر في موقعك الالكتروني كوسيلة لتحويل العملاء المحتملين إلى عملاء فعليين بأسهل الطرق.
                            نفخر بجعل موقعك الالكتروني مصمم بطريقة رائعة و سهل للتعامل مع العملاء و يمكنك ادارته بطريقة سلسلة في جميع المراحل و مزود بجميع أدوات التواصل و الإعلان و إدارة معلومات الزوار.`
                    },
                    digitalMarketing:{
                        title: `التسويق الالكتروني `,
                        body: `استراتيجية متكاملة تمكنك من الوصول إلى عملائك عن طريق الإعلانات المدفوعة أو المحتوى على قنوات التواصل الخاصه بك.

                            فريق عمل منت مرخص من قبل شركة جوجل كفريق مختص من حيث كيفية اعداد الإعلانات و استهداف العملاء المحتملين بتكلفة مرتبطة على العائد من وراء الإعلان على منصات الإعلان التالية : انستجرام ، فيس بوك ، سناب شات ، إعلانات جوجل ، إعلانات البحث الالكتروني ، يوتيوب والعديد من المواقع و التطبيقات.

                            اتصل بنا الآن للوصول إلى مئات الآلاف من العملاء المحتملين في عالم الديجيتال.`
                    },
                    
                }
            }
        }
    },

    methods: {

    }
}
</script>

<style>

    .text-box{
        text-align: center;
    }
    .text-title{
        font-weight: 700;
    }
    .text-body{

    }

    .slide-left {
        left: -500px;
        -webkit-animation: slide-left 0.5s forwards;
        animation: slide-left 1s ease forwards;
        opacity: 0;
    }

    @-webkit-keyframes slide-left {
        100% { 
            left: 0;
            opacity: 1;
        }
    }

    @keyframes slide-left {
        100% { 
            left: 0;
            opacity: 1;
        }
    }

    .slide-right {
        right: -500px;
        -webkit-animation: slide-right 0.5s forwards;
        animation: slide-right 1s ease forwards;
        opacity: 0;
    }

    @-webkit-keyframes slide-right {
        100% { 
            right: 0;
            opacity: 1;
        }
    }

    @keyframes slide-right {
        100% { 
            right: 0;
            opacity: 1;
        }
    }

/* img{
  transform-origin: top right;
  transition: transform 300ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
  transition-delay: 100ms;
  transform: translateX(21%) rotateZ(13deg) skewX(3deg);
  pointer-events: none;
} */
    .floating{
        animation: float 4s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate;
        /* position: absolute; */
        top: 0; right: 0;
        pointer-events: none;
        backface-visibility: hidden;
    }

    @keyframes float{
    0%{
        transform: translateZ(20px);
    }
    100%{
        transform: translateY(-21px) translateX(-13px) translateZ(30px);
    }
    }

    .price-tier-card{
        transition: 0.3s;
        box-shadow: 0px 0px 5px #00000040;
        padding-bottom: 5px
    }

    .price-tier-card:hover{
        transform: scale(1.1);
        box-shadow: 0px 0px 20px #0ed9b0;
    }

    .price-tier-card-header{
        border-radius: 20px 20px 0px 0px; 
        color: white; 
        font-weight: 700; 
        border-bottom: 2px solid black;
        text-shadow: 0px 0px 5px #000000e6;
    }

    .select-tier-btn{
        transition: 0.3s;
        width: 50%;
        padding: 5px;
        margin: 10px;
        background: #0ed9b0;
        color: white;
        border-radius: 20px
    }

    .select-tier-btn:hover{
        transform: scale(1.1);
    }

    body {
  background: #000000;
}
.button {
  display: inline-block;
  margin: 0.3em;
  padding: 0.8em 1.3em;
  width: 10em;
  text-align: center;
  letter-spacing: 0.2em;
  font-size: 1em;
}
.button-gold {
  /* color: rgba(0, 0, 0, 0.72);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(122, 98, 0, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34)
  ; */
  border: 1px solid #deca73;
  background:
    -moz-linear-gradient(
    -72deg,
    #ffde45,
    #ffffff 16%,
    #ffde45 21%,
    #ffffff 24%,
    #452100 27%,
    #ffde45 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffde45 72%,
    #ffffff 80%,
    #ffde45 84%,
    #452100
  );
  background:
  -webkit-linear-gradient(
    -72deg,
    #ffde45,
    #ffffff 16%,
    #ffde45 21%,
    #ffffff 24%,
    #452100 27%,
    #ffde45 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffde45 72%,
    #ffffff 80%,
    #ffde45 84%,
    #452100
  );
  background:
  -o-linear-gradient(
    -72deg,
    #ffde45,
    #ffffff 16%,
    #ffde45 21%,
    #ffffff 24%,
    #452100 27%,
    #ffde45 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffde45 72%,
    #ffffff 80%,
    #ffde45 84%,
    #452100
  );
  background:
  linear-gradient(
    -72deg,
    #ffde45,
    #ffffff 16%,
    #ffde45 21%,
    #ffffff 24%,
    #452100 27%,
    #ffde45 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffde45 72%,
    #ffffff 80%,
    #ffde45 84%,
    #452100
  );
}

.button-bronze {
  /* color: rgba(45, 00, 0, 0.55);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #dea173;
  box-shadow:
    2px 2px 0.5em rgba(122, 55, 34, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5)
  ; */
  border: 1px solid #dea173;
  background:
  -moz-linear-gradient(
    -72deg,
    #ca7345,
    #ffdeca 16%,
    #ca7345 21%,
    #ffdeca 24%,
    #a14521 27%,
    #ca7345 36%,
    #ffdeca 45%,
    #ffdeca 60%,
    #ca7345 72%,
    #ffdeca 80%,
    #ca7345 84%,
    #732100
  );
  background:
  -webkit-linear-gradient(
    -72deg,
    #ca7345,
    #ffdeca 16%,
    #ca7345 21%,
    #ffdeca 24%,
    #a14521 27%,
    #ca7345 36%,
    #ffdeca 45%,
    #ffdeca 60%,
    #ca7345 72%,
    #ffdeca 80%,
    #ca7345 84%,
    #732100
  );
  background:
  -o-linear-gradient(
    -72deg,
    #ca7345,
    #ffdeca 16%,
    #ca7345 21%,
    #ffdeca 24%,
    #a14521 27%,
    #ca7345 36%,
    #ffdeca 45%,
    #ffdeca 60%,
    #ca7345 72%,
    #ffdeca 80%,
    #ca7345 84%,
    #732100
  );
  background:
  linear-gradient(
    -72deg,
    #ca7345,
    #ffdeca 16%,
    #ca7345 21%,
    #ffdeca 24%,
    #a14521 27%,
    #ca7345 36%,
    #ffdeca 45%,
    #ffdeca 60%,
    #ca7345 72%,
    #ffdeca 80%,
    #ca7345 84%,
    #732100
  );
}

.button-platinum {
  /* color: rgba(0, 0, 0, 0.72);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5)
  ; */
  border: 1px solid #cacade;
  background:
    -moz-linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #555564 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
  background:
  -webkit-linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #555564 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
  background:
  -o-linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #555564 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
  background:
  linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #555564 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
}



/* .flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
} */


</style>