<template>
  <div class="container flex flex-wrap" >

    <div class="mt-16 w-full text-center text-6xl text-blue-400" v-html="title"></div>

    <div class="pt-4 flex justify-center">
      <p class="text-center text-2xl" v-html="text"></p>
    </div>

    <div v-if="showContactUsBox" class="p-4 w-full h-1/2">
      <v-card class="p-2 m-2" style="border-radius:20px">
        <v-form v-model="valid">

        <v-text-field :rules="[value => !!value || 'Required', emailRule]" label="email" v-model="email"></v-text-field>
        <v-textarea :rules="[value => !!value || 'Required']" label="Message" v-model="message"></v-textarea>
        <div class="flex justify-end">
          <v-btn @click.prevent="submit" color="black" style="color: #970000 !important; font-weight:700" rounded outlined>Submit</v-btn>
        </div>

        </v-form>
      </v-card>
    </div>

    <div v-if="!showContactUsBox" class="p-4 mt-16 w-full h-1/2">
      <v-card class="p-2 m-2 flex justify-center">
        <p class="text-center text-4xl">Your inquiry has been submitted successfully!</p>
      </v-card>
      <div class="p-4 mt-16 w-full h-1/2 flex justify-center">
        <v-btn @click.prevent="showContactUsBox = true">Submit another inquiry</v-btn>
      </div>
    </div>



  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ContactUs",
  components: {},

  props:['text', 'title'],

  data() {
    return {
      valid: false,
      showContactUsBox: true,
      email: null,
      message: null,
      emailRule: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail'
      }
    };
  },

  methods: {
        submit(){
          if(this.valid){
            let contactUsMsg = new FormData();
            contactUsMsg.set('email', this.email);
            contactUsMsg.set('message', this.message);
           
            axios({
              method: "post",
              url: this.$store.state.api + "/contactUs",
              data: contactUsMsg,
              headers: {
              "Content-Type": "multipart/form-data",
              }
            }).then(response => {
                this.showContactUsBox = false
            });

          }
        }
  }
};
</script>
