import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: 'https://smrk.app/api',
    // api: 'http://127.0.0.1:8000/api',
    lang: 'en'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
